<template>
  <log-quick-submit
    v-model="note"
    :placeholder="$t('ACTIVITY.CONTACTS.WRITE_NOTES_ABOUT_THIS_CALL')"
    :return-placeholder="$t('ACTIVITY.CONTACTS.TO_LOG_A_CALL')"
    :loading="loading"
    :text-required="false"
    :is-modal="isModal"
    focus-on-mount
    @submit="onSubmit"
  >
    <template #supplemental>
      <slot name="supplemental" />
      <div
        v-if="isModal"
        class="bg-grey-hover flex items-center justify-end px-3 pt-1 text-xs"
      >
        <div class="flex items-center space-x-2">
          <span class="text-label">
            {{ $t("ACTIVITY.CALLS.UPLOAD_RECORDING") }}
          </span>
          <lf-switch v-model="uploadAfterSave" name="upload_recording" />
        </div>
      </div>
    </template>
    <template #actionBar>
      <div class="flex items-center space-x-2">
        <notes-dropdown
          v-model="callDisposition"
          :options="callStatuses"
          :colors-for-options="COLORS_BY_DISPOSITION_NOTE_STATUS"
          :placeholder="$t('ACTIVITY.SELECT_CALL_DISPOSITION')"
          class="min-w-50"
        />
        <button
          v-tooltip="$t('DEALS.DEAL_NOTES.VISIBLE_IN_DEALS_LIST')"
          data-cy="visible-in-deals-list"
          class="p-1 py-3 flex justify-center items-center w-10 rounded-md cursor-pointer h-9"
          :class="{ 'bg-primary': visibleInDealsList }"
          @click.prevent.stop="toggleVisibleInDealsList"
        >
          <icon-base
            :icon="IconListView"
            height="20"
            width="20"
            view-box="0 0 24 24"
            class="mx-1 cursor-pointer"
            :class="visibleInDealsList ? 'text-white' : 'text-gray-400'"
          />
        </button>
      </div>
    </template>
  </log-quick-submit>
</template>

<script lang="ts">
import { computed, ref } from "vue";
import { usePromiseWrapper } from "@/hooks/common";
import { useCommunicationStore } from "@/stores/communication";
import { useDeals } from "@/hooks/deals";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import type { CallLog, CallNotePayload } from "@/models/communicationLogs";
import { COLORS_BY_DISPOSITION_NOTE_STATUS } from "@/helpers/constants/colors";
import type { CallDisposition } from "@/enums/communicationLogs";
import LogQuickSubmit from "@/components/communicationLogs/forms/LogQuickSubmit.vue";
import IconListView from "@/components/icons/IconListView.vue";
import LfSwitch from "@/components/ui/inputs/LfSwitch.vue";

interface Props {
  callLog?: CallLog;
  isModal?: boolean;
}
</script>
<script setup lang="ts">
import NotesDropdown from "@/components/ui/NotesDropdown.vue";

const { callLog, isModal } = defineProps<Props>();
const emit = defineEmits<{
  "callNote:edit": [];
  "callNote:create": [upload: boolean, note: CallLog];
}>();

const { getCommunicationList, updateCallNote, createCallNote } =
  useCommunicationStore();
const { getters } = useStore();
const { activeDeal } = useDeals();
const route = useRoute();

const note = ref(callLog?.data?.body ?? "");
const callDisposition = ref(callLog?.data?.call_disposition);
const visibleInDealsList = ref(true);
const uploadAfterSave = ref(false);

const callDispositions = computed<string[]>(
  () => getters["options/callDispositions"]
);

const callStatuses = computed(() =>
  Object.entries(callDispositions.value).map(([id, value]) => ({
    id,
    name: value
  }))
);

const { loading, fetchWrapper: updateNote } = usePromiseWrapper(
  async (payload: CallNotePayload) => {
    if (!callLog) {
      return;
    }
    await updateCallNote(callLog.data.id, payload);
    emit("callNote:edit");
    const filters = route.query;

    await getCommunicationList(filters);
  }
);

const { fetchWrapper: createNote } = usePromiseWrapper(
  async (payload: CallNotePayload) => {
    const note = await createCallNote(activeDeal.value.id, payload);
    emit("callNote:create", uploadAfterSave.value, note);
  }
);

const toggleVisibleInDealsList = () => {
  visibleInDealsList.value = !visibleInDealsList.value;
};

const onSubmit = async () => {
  const payload = {
    note: note.value,
    call_disposition: callDisposition.value as CallDisposition,
    visible_in_deal_list: visibleInDealsList.value
  };
  const action = callLog ? updateNote : createNote;
  await action(payload);
};
</script>
